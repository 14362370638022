import React, { useState } from 'react';
import { 
  Button, Typography, Container, Grid, Paper, TextField, Alert, 
  Dialog, DialogActions, DialogContent, DialogContentText, 
  DialogTitle, Card, CardContent, CardActions, Box, Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AddIcon from '@mui/icons-material/Add';
import { useAppContext } from '../AppContext';

const DashboardCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

const DashboardButton = styled(Button)(({ theme }) => ({
  marginTop: 'auto',
}));

const AdminDashboard = () => {
  const { register } = useAppContext();
  const [doctorEmail, setDoctorEmail] = useState('');
  const [deliveryEmail, setDeliveryEmail] = useState('');
  const [openDoctorDialog, setOpenDoctorDialog] = useState(false);
  const [openDeliveryDialog, setOpenDeliveryDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const handleAddDoctor = async () => {
    try {
      await register(doctorEmail, 'defaultPassword', 'doctor');
      setSnackbar({ open: true, message: 'Doctor added successfully!', severity: 'success' });
      setDoctorEmail('');
      setOpenDoctorDialog(false);
    } catch (err) {
      console.error('Error adding doctor:', err);
      setSnackbar({ open: true, message: 'Failed to add doctor.', severity: 'error' });
    }
  };

  const handleAddDelivery = async () => {
    try {
      await register(deliveryEmail, 'defaultPassword', 'delivery');
      setSnackbar({ open: true, message: 'Delivery person added successfully!', severity: 'success' });
      setDeliveryEmail('');
      setOpenDeliveryDialog(false);
    } catch (err) {
      console.error('Error adding delivery person:', err);
      setSnackbar({ open: true, message: 'Failed to add delivery person.', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh', py: 3 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4, fontWeight: 'bold', color: 'text.primary' }}>
          Admin Dashboard
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard elevation={3}>
              <CardContent>
                <PersonIcon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }} />
                <Typography variant="h6" component="div" gutterBottom>
                  Patient Dashboard
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  View and manage patient information and test orders.
                </Typography>
              </CardContent>
              <CardActions>
                <DashboardButton variant="contained" color="primary" fullWidth href="/patient">
                  View Dashboard
                </DashboardButton>
              </CardActions>
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard elevation={3}>
              <CardContent>
                <LocalHospitalIcon sx={{ fontSize: 40, color: 'secondary.main', mb: 2 }} />
                <Typography variant="h6" component="div" gutterBottom>
                  Doctor Dashboard
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Manage test results and patient prescriptions.
                </Typography>
              </CardContent>
              <CardActions>
                <DashboardButton variant="contained" color="secondary" fullWidth href="/doctor">
                  View Dashboard
                </DashboardButton>
              </CardActions>
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard elevation={3}>
              <CardContent>
                <DeliveryDiningIcon sx={{ fontSize: 40, color: 'success.main', mb: 2 }} />
                <Typography variant="h6" component="div" gutterBottom>
                  Delivery Dashboard
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Track and manage test kit deliveries and pickups.
                </Typography>
              </CardContent>
              <CardActions>
                <DashboardButton variant="contained" color="success" fullWidth href="/delivery">
                  View Dashboard
                </DashboardButton>
              </CardActions>
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard elevation={3}>
              <CardContent>
                <AddIcon sx={{ fontSize: 40, color: 'info.main', mb: 2 }} />
                <Typography variant="h6" component="div" gutterBottom>
                  Add New Doctor
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Register a new doctor to the system.
                </Typography>
              </CardContent>
              <CardActions>
                <DashboardButton variant="contained" color="info" fullWidth onClick={() => setOpenDoctorDialog(true)}>
                  Add Doctor
                </DashboardButton>
              </CardActions>
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard elevation={3}>
              <CardContent>
                <AddIcon sx={{ fontSize: 40, color: 'warning.main', mb: 2 }} />
                <Typography variant="h6" component="div" gutterBottom>
                  Add Delivery Person
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Register a new delivery person to the system.
                </Typography>
              </CardContent>
              <CardActions>
                <DashboardButton variant="contained" color="warning" fullWidth onClick={() => setOpenDeliveryDialog(true)}>
                  Add Delivery Person
                </DashboardButton>
              </CardActions>
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard elevation={3}>
              <CardContent>
                <AnalyticsIcon sx={{ fontSize: 40, color: 'error.main', mb: 2 }} />
                <Typography variant="h6" component="div" gutterBottom>
                  Data Analytics
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  View comprehensive data analytics and reports.
                </Typography>
              </CardContent>
              <CardActions>
                <DashboardButton variant="contained" color="error" fullWidth href="/analytics">
                  View Analytics
                </DashboardButton>
              </CardActions>
            </DashboardCard>
          </Grid>
        </Grid>

        {/* Doctor Dialog */}
        <Dialog open={openDoctorDialog} onClose={() => setOpenDoctorDialog(false)}>
          <DialogTitle>Add a New Doctor</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the email address of the new doctor. A temporary password will be set.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Doctor's Email"
              type="email"
              fullWidth
              variant="outlined"
              value={doctorEmail}
              onChange={(e) => setDoctorEmail(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDoctorDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddDoctor} color="primary" variant="contained">
              Add Doctor
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delivery Person Dialog */}
        <Dialog open={openDeliveryDialog} onClose={() => setOpenDeliveryDialog(false)}>
          <DialogTitle>Add a New Delivery Person</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the email address of the new delivery person. A temporary password will be set.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Delivery Person's Email"
              type="email"
              fullWidth
              variant="outlined"
              value={deliveryEmail}
              onChange={(e) => setDeliveryEmail(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
          <Button onClick={() => setOpenDeliveryDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddDelivery} color="primary" variant="contained">
              Add Delivery Person
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default AdminDashboard;