import React, { useState, useEffect } from 'react';
import { 
  Button, TextField, Typography, Grid, Box, 
  CircularProgress, Select, MenuItem, FormControl, InputLabel,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Chip, Dialog, DialogTitle, DialogContent, DialogActions,
  Stepper, Step, StepLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ScienceIcon from '@mui/icons-material/Science';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useAppContext } from '../AppContext';

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.light,
}));

const steps = ['Ordered', 'Picked Up', 'Delivered to Lab', 'Processing', 'Results Available'];

const DoctorDashboard = () => {
  const { orders, loading, updateOrder, fetchOrders, user } = useAppContext();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    console.log('Fetching orders for user:', user); // Debugging user data
    fetchOrders(); // Ensure orders are fetched when the component mounts
  }, [fetchOrders, user]);

  const getStepIcon = (step, currentStatus) => {
    const isCompleted = steps.indexOf(currentStatus) >= steps.indexOf(step);
    switch (step) {
      case 'Ordered':
        return isCompleted ? <CheckCircleIcon color="success" /> : <LocalHospitalIcon color="primary" />;
      case 'Picked Up':
      case 'Delivered to Lab':
        return isCompleted ? <CheckCircleIcon color="success" /> : <TwoWheelerIcon color="secondary" />;
      case 'Processing':
        return isCompleted ? <CheckCircleIcon color="success" /> : <ScienceIcon color="warning" />;
      case 'Results Available':
        return currentStatus === 'Results Available' ? <CheckCircleIcon color="success" /> : <CheckCircleIcon color="disabled" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Ordered':
      case 'Picked Up':
      case 'Delivered to Lab':
        return 'info';
      case 'Processing':
        return 'warning';
      case 'Results Available':
        return 'success';
      default:
        return 'default';
    }
  };

  const handleViewMore = (order) => {
    setSelectedOrder(order);
    setOpenDialog(true);
  };

  const handleStartProcessing = async () => {
    if (selectedOrder && selectedOrder.status === 'Delivered to Lab') {
      try {
        await updateOrder(selectedOrder._id, { status: 'Processing' });
        setSelectedOrder({ ...selectedOrder, status: 'Processing' });
      } catch (error) {
        console.error('Error starting processing:', error);
      }
    }
  };

  const handleSubmit = async () => {
    if (selectedOrder && selectedOrder.result) {
      try {
        await updateOrder(selectedOrder._id, { 
          status: 'Results Available',
          result: selectedOrder.result,
          comments: selectedOrder.comments,
          prescription: selectedOrder.prescription,
          recommendation: selectedOrder.recommendation
        });
        setOpenDialog(false);
      } catch (error) {
        console.error('Error submitting results:', error);
      }
    } else {
      alert('Please select a test result before submitting.');
    }
  };

  const getActionButton = (order) => {
    switch (order.status) {
      case 'Delivered to Lab':
        return (
          <ActionButton
            onClick={() => handleViewMore(order)}
            startIcon={<PlayArrowIcon />}
          >
            Start Processing
          </ActionButton>
        );
      case 'Processing':
        return (
          <ActionButton
            onClick={() => handleViewMore(order)}
            startIcon={<ScienceIcon />}
          >
            Process Results
          </ActionButton>
        );
      case 'Results Available':
        return (
          <ActionButton
            onClick={() => handleViewMore(order)}
            startIcon={<VisibilityIcon />}
          >
            View Results
          </ActionButton>
        );
      default:
        return (
          <ActionButton disabled>
            No Action
          </ActionButton>
        );
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#333' }}>
        Doctor Dashboard
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Order ID</StyledTableCell>
                <StyledTableCell>Patient Name</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Result</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order._id}>
                  <TableCell>{order._id}</TableCell>
                  <TableCell>{order.patientName}</TableCell>
                  <TableCell>
                    <Chip 
                      label={order.status} 
                      color={getStatusColor(order.status)}
                      icon={getStepIcon(order.status, order.status)}
                    />
                  </TableCell>
                  <TableCell>{order.result || 'Pending'}</TableCell>
                  <TableCell>
                    {getActionButton(order)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedOrder && selectedOrder.status === 'Results Available' ? 'View Test Results' : 
           selectedOrder && selectedOrder.status === 'Delivered to Lab' ? 'Start Processing' : 'Process Test Results'}
        </DialogTitle>
        <DialogContent>
          {selectedOrder && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stepper activeStep={steps.indexOf(selectedOrder.status)} alternativeLabel sx={{ mt: 2, mb: 3 }}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={() => getStepIcon(label, selectedOrder.status)}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography><strong>Order ID:</strong> {selectedOrder._id}</Typography>
                <Typography><strong>Patient:</strong> {selectedOrder.patientName}</Typography>
                <Typography><strong>Phone:</strong> {selectedOrder.phoneNumber}</Typography>
                <Typography><strong>Status:</strong> {selectedOrder.status}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Test Result</InputLabel>
                  <Select
                    value={selectedOrder.result || ''}
                    onChange={(e) => setSelectedOrder({...selectedOrder, result: e.target.value})}
                    disabled={selectedOrder.status === 'Results Available' || selectedOrder.status === 'Delivered to Lab'}
                  >
                    <MenuItem value="Negative">Negative</MenuItem>
                    <MenuItem value="Positive">Positive</MenuItem>
                    <MenuItem value="Inconclusive">Inconclusive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Comments"
                  multiline
                  rows={3}
                  value={selectedOrder.comments || ''}
                  onChange={(e) => setSelectedOrder({...selectedOrder, comments: e.target.value})}
                  disabled={selectedOrder.status === 'Results Available' || selectedOrder.status === 'Delivered to Lab'}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Prescription"
                  multiline
                  rows={3}
                  value={selectedOrder.prescription || ''}
                  onChange={(e) => setSelectedOrder({...selectedOrder, prescription: e.target.value})}
                  disabled={selectedOrder.status === 'Results Available' || selectedOrder.status === 'Delivered to Lab'}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Recommendation"
                  multiline
                  rows={3}
                  value={selectedOrder.recommendation || ''}
                  onChange={(e) => setSelectedOrder({...selectedOrder, recommendation: e.target.value})}
                  disabled={selectedOrder.status === 'Results Available' || selectedOrder.status === 'Delivered to Lab'}
                  sx={{ mb: 2 }}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
          {selectedOrder && selectedOrder.status === 'Delivered to Lab' && (
            <ActionButton onClick={handleStartProcessing}>Start Processing</ActionButton>
          )}
          {selectedOrder && selectedOrder.status === 'Processing' && (
            <ActionButton onClick={handleSubmit}>Submit Results</ActionButton>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DoctorDashboard;
