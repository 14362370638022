import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Button, Container, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PatientDashboard from './components/PatientDashboard';
import DoctorDashboard from './components/DoctorDashboard';
import DeliveryDashboard from './components/DeliveryDashboard';
import Login from './components/Login';
import Register from './components/Register';
import ContactUs from './components/ContactUs';
import { AppProvider, useAppContext } from './AppContext';
import AdminDashboard from './components/AdminDashboard';
import io from 'socket.io-client';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const Navigation = () => {
  const context = useAppContext();
  const { user, logout } = context || {};
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const getDashboardLink = () => {
    switch (user?.role) {
      case 'patient':
        return '/patient';
      case 'doctor':
        return '/doctor';
      case 'delivery':
        return '/delivery';
      case 'admin':
        return '/admin';
      default:
        return '/';
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Health Test Kit App
        </Typography>
        {user ? (
          <>
            <Button color="inherit" href={getDashboardLink()}>Dashboard</Button>
            <Button color="inherit" href="/contact-us">Contact Us</Button>
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          </>
        ) : (
          <>
            <Button color="inherit" href="/login">Login</Button>
            <Button color="inherit" href="/register">Register</Button>
          </>
        )}
        {user && user.role === 'admin' && <Button color="inherit" href="/admin">Admin</Button>}
      </Toolbar>
    </AppBar>
  );
};

function App() {
  const context = useAppContext();
  const { user } = context || {};

  useEffect(() => {
    const socket = io('http://localhost:5000'); // Adjust the URL if your server runs on a different address/port

    socket.on('connect', () => {
      console.log('Connected to the server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from the server');
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const getDashboardComponent = () => {
    switch (user?.role) {
      case 'patient':
        return <PatientDashboard />;
      case 'doctor':
        return <DoctorDashboard />;
      case 'delivery':
        return <DeliveryDashboard />;
      case 'admin':
        return <AdminDashboard />;
      default:
        return <Navigate to="/login" />;
    }
  };

  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Navigation />
          <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/" element={<Navigate replace to={user ? '/dashboard' : '/login'} />} />
              <Route path="/dashboard" element={getDashboardComponent()} />
              <Route path="/patient" element={<PatientDashboard />} />
              <Route path="/doctor" element={<DoctorDashboard />} />
              <Route path="/delivery" element={<DeliveryDashboard />} />
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/unauthorized" element={<Typography>You are not authorized to view this page.</Typography>} />
            </Routes>
          </Container>
        </Router>
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;
