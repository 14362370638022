import React, { useState } from 'react';

const containerStyle = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  fontFamily: 'Arial, sans-serif',
};

const headingStyle = {
  fontSize: '2.5rem',
  marginBottom: '10px',
};

const subheadingStyle = {
  fontSize: '1.2rem',
  color: '#666',
  marginBottom: '30px',
};

const formStyle = {
  marginBottom: '30px',
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  marginBottom: '15px',
  border: '1px solid #ccc',
  borderRadius: '4px',
};

const buttonStyle = {
  backgroundColor: '#4CAF50',
  color: 'white',
  padding: '12px 20px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '1rem',
};

const contactInfoStyle = {
  backgroundColor: '#f9f9f9',
  padding: '20px',
  borderRadius: '4px',
};

const iconStyle = {
  marginRight: '10px',
  color: '#4CAF50',
};

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setIsSubmitted(true);
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Contact Us</h1>
      <p style={subheadingStyle}>
        We'd love to hear from you! Get in touch with us for any questions or concerns.
      </p>

      <div style={formStyle}>
        <h2>Send us a message</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
            style={inputStyle}
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            style={inputStyle}
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            required
            style={{...inputStyle, height: '100px'}}
          ></textarea>
          <button type="submit" style={buttonStyle}>
            Send Message ➤
          </button>
        </form>
        {isSubmitted && (
          <p style={{color: '#4CAF50', marginTop: '15px'}}>
            Your message has been sent. We'll get back to you soon.
          </p>
        )}
      </div>

      <div style={contactInfoStyle}>
        <h2>Contact Information</h2>
        <p><span style={iconStyle}>📞</span> +1 (555) 123-4567</p>
        <p><span style={iconStyle}>✉️</span> support@healthtestkitapp.com</p>
        <p><span style={iconStyle}>📍</span> 123 Health St, Medical City, MC 12345</p>
      </div>
    </div>
  );
};

export default ContactUs;