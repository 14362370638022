import React, { useState } from 'react';
import { 
  Button, Typography, Card, CardContent, Grid, Box, CircularProgress, 
  Stepper, Step, StepLabel, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, Chip, Dialog, DialogTitle, DialogContent, 
  DialogActions, TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ScienceIcon from '@mui/icons-material/Science';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAppContext } from '../AppContext';

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.light,
}
));

const steps = ['Ordered', 'Picked Up', 'Delivered to Lab'];

const DeliveryDashboard = () => {
  const { orders, loading, updateOrder } = useAppContext();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [notes, setNotes] = useState('');

  const getStepIcon = (step, currentStatus) => {
    const isCompleted = steps.indexOf(currentStatus) >= steps.indexOf(step);
    switch (step) {
      case 'Ordered':
        return isCompleted ? <CheckCircleIcon color="success" /> : <LocalHospitalIcon color="primary" />;
      case 'Picked Up':
        return isCompleted ? <CheckCircleIcon color="success" /> : <TwoWheelerIcon color="secondary" />;
      case 'Delivered to Lab':
        return currentStatus === 'Delivered to Lab' ? <CheckCircleIcon color="success" /> : <ScienceIcon color="warning" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Ordered':
        return 'info';
      case 'Picked Up':
        return 'warning';
      case 'Delivered to Lab':
        return 'success';
      default:
        return 'default';
    }
  };

  const handleViewMore = (order) => {
    setSelectedOrder(order);
    setNotes(order.deliveryNotes || '');
    setOpenDialog(true);
  };

  const handleUpdateStatus = async () => {
    if (selectedOrder) {
      const currentIndex = steps.indexOf(selectedOrder.status);
      if (currentIndex < steps.length - 1) {
        const newStatus = steps[currentIndex + 1];
        try {
          await updateOrder(selectedOrder._id, { status: newStatus, deliveryNotes: notes });
          setSelectedOrder({ ...selectedOrder, status: newStatus, deliveryNotes: notes });
          setOpenDialog(false);
        } catch (error) {
          console.error('Error updating status:', error);
          // Handle error (e.g., show an error message to the user)
        }
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#333' }}>
        Delivery Dashboard
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {orders.filter(order => order.status !== 'Delivered to Lab').map((order) => (
            <Grid item xs={12} sm={6} md={4} key={order._id}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Order ID: {order._id}</Typography>
                  <Typography color="textSecondary" gutterBottom>Patient: {order.patientName}</Typography>
                  <Typography>Address: {order.address}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 2 }}>
                    <PhoneIcon sx={{ mr: 1 }} />
                    <Typography component="a" href={`tel:${order.phoneNumber}`} color="inherit">
                      {order.phoneNumber}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%', mb: 2 }}>
                    <Stepper activeStep={steps.indexOf(order.status)} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel StepIconComponent={() => getStepIcon(label, order.status)}>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <ColorButton 
                      variant="contained" 
                      fullWidth
                      onClick={() => handleViewMore(order)}
                    >
                      View Details
                    </ColorButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent>
          {selectedOrder && (
            <>
              <Typography><strong>Order ID:</strong> {selectedOrder._id}</Typography>
              <Typography><strong>Patient:</strong> {selectedOrder.patientName}</Typography>
              <Typography><strong>Address:</strong> {selectedOrder.address}</Typography>
              <Typography><strong>Phone:</strong> {selectedOrder.phoneNumber}</Typography>
              <Typography><strong>Status:</strong> {selectedOrder.status}</Typography>
              <TextField
                margin="dense"
                label="Delivery Notes"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
          <Button onClick={handleUpdateStatus} color="primary">
            Update Status
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeliveryDashboard;