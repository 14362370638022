import React, { createContext, useState, useContext, useEffect } from 'react';
import io from 'socket.io-client';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      verifyToken(token);
    } else {
      setLoading(false);
    }

    const newSocket = io(process.env.REACT_APP_SOCKET_URL);
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('orderUpdate', (updatedOrder) => {
        setOrders(prevOrders => 
          prevOrders.map(order => order._id === updatedOrder._id ? updatedOrder : order)
        );
      });

      socket.on('newOrder', (newOrder) => {
        setOrders(prevOrders => [...prevOrders, newOrder]);
      });
    }
  }, [socket]);

  const verifyToken = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/verify`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data.user);
        fetchOrders(token);
      } else {
        throw new Error('Token verification failed');
      }
    } catch (error) {
      console.error('Token verification failed:', error);
      logout();
    }
  };

  const fetchOrders = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/orders`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setOrders(data);
      } else {
        throw new Error('Failed to fetch orders');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        setUser(data.user);
        await fetchOrders(data.token);
        return data.user;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const register = async (email, password, name, address, phoneNumber) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, name, address, phoneNumber }),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        setUser(data.user);
        await fetchOrders(data.token);
        return data.user;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setOrders([]);
    if (socket) {
      socket.disconnect();
    }
  };

  const addOrder = async (newOrder) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newOrder),
      });
      
      if (response.ok) {
        const data = await response.json();
        setOrders(prevOrders => [...prevOrders, data]);
        socket.emit('newOrder', data);
        return data;
      } else {
        const textResponse = await response.text();
        console.error('Server response:', textResponse);
        console.error('Response status:', response.status);
        console.error('Response headers:', response.headers);
        throw new Error(`Server error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error adding order:', error);
      throw error;
    }
  };
  

  const updateOrder = async (id, updates) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/orders/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updates),
      });
      if (response.ok) {
        const data = await response.json();
        setOrders(prevOrders => prevOrders.map(order => 
          order._id === id ? { ...order, ...data } : order
        ));
        socket.emit('orderUpdate', data);
        return data;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update order');
      }
    } catch (error) {
      console.error('Error updating order:', error);
      throw error;
    }
  };

  return (
    <AppContext.Provider value={{ 
      user, 
      orders, 
      loading, 
      login, 
      logout, 
      register, 
      addOrder, 
      updateOrder,
      fetchOrders 
    }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
