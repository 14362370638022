import React, { useState, useEffect } from 'react';
import { 
  Button, TextField, Typography, Card, CardContent, Grid, Box, 
  CircularProgress, Stepper, Step, StepLabel, Chip,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert,
  AppBar, Toolbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ScienceIcon from '@mui/icons-material/Science';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.light,
}));

const steps = ['Ordered', 'Picked Up', 'Delivered to Lab', 'Processing', 'Results Available'];

const PatientDashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mainSymptoms, setMainSymptoms] = useState('');
  const [howStarted, setHowStarted] = useState('');
  const [whenStarted, setWhenStarted] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      fetchUserData(token);
      fetchOrders(token);
    }
  }, [navigate]);

  const fetchUserData = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const userData = await response.json();
      setUser(userData);
      setName(userData.name || '');
      setAddress(userData.address || '');
      setPhoneNumber(userData.phoneNumber || '');
    } catch (error) {
      console.error('Error fetching user data:', error.message);
      setSnackbar({ open: true, message: `Error fetching user data: ${error.message}`, severity: 'error' });
    }
  };

  const fetchOrders = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/order`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const orderData = await response.json();
      setOrders(orderData);
    } catch (error) {
      console.error('Error fetching orders:', error.message);
      setSnackbar({ open: true, message: `Error fetching orders: ${error.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const placeOrder = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          patientName: name,
          address,
          phoneNumber,
          mainSymptoms,
          howStarted,
          whenStarted: whenStarted ? new Date(whenStarted).toISOString() : null,  // Convert to ISO format
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const newOrder = await response.json();
      console.log('New order:', newOrder); // Debugging
      setOrders([newOrder, ...orders]);
      setSnackbar({ open: true, message: 'Order placed successfully!', severity: 'success' });
      // Clear form fields after successful order
      setAddress('');
      setPhoneNumber('');
      setMainSymptoms('');
      setHowStarted('');
      setWhenStarted('');
    } catch (error) {
      console.error('Error placing order:', error.message);
      setSnackbar({ open: true, message: `Error placing order: ${error.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleContactUs = () => {
    console.log('Contact Us clicked');
    // Implement contact functionality here
  };

  const getStepIcon = (step, currentStatus) => {
    const isCompleted = steps.indexOf(currentStatus) >= steps.indexOf(step);
    switch (step) {
      case 'Ordered':
        return isCompleted ? <CheckCircleIcon color="success" /> : <LocalHospitalIcon color="primary" />;
      case 'Picked Up':
      case 'Delivered to Lab':
        return isCompleted ? <CheckCircleIcon color="success" /> : <TwoWheelerIcon color="secondary" />;
      case 'Processing':
        return isCompleted ? <CheckCircleIcon color="success" /> : <ScienceIcon color="warning" />;
      case 'Results Available':
        return currentStatus === 'Results Available' ? <CheckCircleIcon color="success" /> : <CheckCircleIcon color="disabled" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Ordered':
        return 'info';
      case 'Picked Up':
      case 'Delivered to Lab':
      case 'Processing':
        return 'warning';
      case 'Results Available':
        return 'success';
      default:
        return 'default';
    }
  };

  const handleViewMore = async (order) => {
    setSelectedOrder(order);
    setOpenDialog(true);

    // If the order has results and hasn't been viewed, mark it as viewed
    if (order.status === 'Results Available' && !order.viewedByPatient) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/order/${order._id}/view`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Update the local state to reflect that the order has been viewed
        const updatedOrders = orders.map(o => 
          o._id === order._id ? { ...o, viewedByPatient: true } : o
        );
        setOrders(updatedOrders);
      } catch (error) {
        console.error('Error marking order as viewed:', error.message);
      }
    }
  };

  const renderOrderDetails = (order) => (
    <Box key={order._id} sx={{ mb: 4 }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Order ID: {order._id}
      </Typography>
      <Stepper activeStep={steps.indexOf(order.status)} alternativeLabel sx={{ mt: 2, mb: 3 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={() => getStepIcon(label, order.status)}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography><strong>Name:</strong> {order.patientName || 'N/A'}</Typography>
          <Typography><strong>Address:</strong> {order.address || 'N/A'}</Typography>
          <Typography><strong>Phone:</strong> {order.phoneNumber || 'N/A'}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography><strong>Order Date:</strong> {order.orderDate ? new Date(order.orderDate).toLocaleString() : 'N/A'}</Typography>
          <Typography><strong>Status:</strong> {order.status || 'N/A'}</Typography>
          {order.result && <Typography><strong>Result:</strong> {order.result}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <Typography><strong>Main Symptoms:</strong> {order.mainSymptoms || 'N/A'}</Typography>
          <Typography><strong>How it Started:</strong> {order.howStarted || 'N/A'}</Typography>
          <Typography><strong>When it Started:</strong> {order.whenStarted ? new Date(order.whenStarted).toLocaleDateString() : 'N/A'}</Typography>
        </Grid>
        {order.status === 'Results Available' && (
          <>
            <Grid item xs={12}>
              <Typography><strong>Doctor's Comments:</strong> {order.comments || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography><strong>Prescription:</strong> {order.prescription || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography><strong>Recommendation:</strong> {order.recommendation || 'N/A'}</Typography>
            </Grid>
            {order.viewedByPatient && (
              <Grid item xs={12}>
                <Typography color="primary"><strong>Viewed by Patient</strong></Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1, minHeight: '100vh' }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Health Test Kit App
          </Typography>
          <Button color="inherit" onClick={handleContactUs}>
            Contact Us
          </Button>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 3, backgroundColor: '#f5f5f5' }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#333' }}>
          Welcome, {user ? user.name : 'Patient'}!
        </Typography>
        <Grid container spacing={3}>
          {/* Place New Order Card */}
          <Grid item xs={12} md={4}>
            <Card elevation={3}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                  Place New Order
                </Typography>
                <TextField
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  label="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  label="Main Symptoms"
                  value={mainSymptoms}
                  onChange={(e) => setMainSymptoms(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={2}
                  required
                />
                <TextField
                  label="How it Started"
                  value={howStarted}
                  onChange={(e) => setHowStarted(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={2}
                  required
                />
                <TextField
                  label="When it Started"
                  type="date"
                  value={whenStarted}
                  onChange={(e) => setWhenStarted(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
                <ColorButton 
                  variant="contained" 
                  fullWidth
                  onClick={placeOrder}
                  sx={{ mt: 2 }}
                  disabled={loading || !name || !address || !phoneNumber || !mainSymptoms || !howStarted || !whenStarted}
                >
                  {loading ? <CircularProgress size={24} /> : 'Order Test Kit'}
                </ColorButton>
              </CardContent>
            </Card>
          </Grid>

          {/* Recent Order Card */}
          <Grid item xs={12} md={8}>
            <Card elevation={3}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                  Recent Order
                </Typography>
                {loading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <CircularProgress />
                  </Box>
                ) : orders.length > 0 ? (
                  renderOrderDetails(orders[0])
                ) : (
                  <Typography>No recent orders.</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Order History Card */}
          <Grid item xs={12}>
            <Card elevation={3}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                    Order History
                  </Typography>
                  <Button 
                    variant="outlined" 
                    onClick={() => setShowHistory(!showHistory)}
                  >
                    {showHistory ? 'Hide History' : 'Show History'}
                  </Button>
                </Box>
                {showHistory && (
                  <TableContainer component={Paper} elevation={0}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Order ID</StyledTableCell>
                          <StyledTableCell>Date</StyledTableCell>
                          <StyledTableCell>Status</StyledTableCell>
                          <StyledTableCell>Result</StyledTableCell>
                          <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {orders.map((order) => (
                          <TableRow key={order._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>{order._id}</TableCell>
                            <TableCell>{order.orderDate ? new Date(order.orderDate).toLocaleString() : 'N/A'}</TableCell>
                            <TableCell>
                              <Chip 
                                label={order.status} 
                                color={getStatusColor(order.status)}
                                icon={getStepIcon(order.status, order.status)}
                              />
                            </TableCell>
                            <TableCell>{order.result || 'Pending'}</TableCell>
                            <TableCell>
                              <Button
                                startIcon={<VisibilityIcon />}
                                onClick={() => handleViewMore(order)}
                              >
                                View More
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Order Details Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent>
          {selectedOrder && renderOrderDetails(selectedOrder)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar> 
    </Box>
  );
};

export default PatientDashboard;
